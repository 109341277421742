/**
 * mtl.alloy
 *
 * @version 2016-12-12
 */
;(function($, mtl) {
    'use strict';

    // Initialiser factory
    var factory = {};

    // Alloy instance
    var instance = {
        initAttr: 'data-init',
        factory: factory,
    };

    // Event emitter
    var $emitter = $(instance);


    /**
     * Try to execute a factory function.
     */
    function tryFactory(as, fn, element, options) {

        try {
            fn(element, options);
        }

        catch (e) {
            console.error('Failed to initialize element as "%s": %s', as, e);
        }

    }


    /**
     * Initialise an element with a named factory.
     */
    function initElementAs($element, as, options) {

        var fn = mtl.path.get(factory, as);
        if (fn) {
            tryFactory(as, fn, $element[0], options || {});
        }

        else {
            console.error('Failed to initialize element as "%s": Factory function not defined', as);
        }

    }


    /**
     * Initialse an element according to it's `data-init` attribute.
     */
    function initElement($element) {

        if ($element.data('did-mtl-init')) {
            return;
        }

        var initStr = $element.attr(instance.initAttr);
        if (!initStr) {
            return;
        }

        var init = mtl.lson.parseObjectOrArray(initStr);
        if (!init) {
            return;
        }

        $element.data('did-mtl-init', true);

        // Specified without options: "foo, bar"
        if (Array.isArray(init)) {
            for (var i = 0, ix = init.length; i < ix; i++) {
                initElementAs($element, init[i]);
            }
        }

        // Specified with options: "foo: {enabled: true}, bar: {index: 3}"
        else {
            for (var name in init) {
                initElementAs($element, name, init[name]);
            }
        }

    }


    /**
     * Initialise all elements in a collection.
     */
    function initElements($elements) {

        for (var i = 0, ix = $elements.length; i < ix; i++) {
            initElement($elements.eq(i));
        }

    }


    /**
     * Process all elements in a collection.
     */
    function findAndInit(element, includeSelf, includeChildren) {

        var initSelector = '[' + instance.initAttr + ']';

        var $elements = $(element);

        for (var i = 0, ix = $elements.length; i < ix; i++) {

            var $element = $elements.eq(i);

            if (includeSelf) {
                initElements($element.filter(initSelector));
            }

            if (includeChildren) {
                initElements($element.find(initSelector));
            }

        }

    }


    /**
     * Process an eligible element.
     */
    function processElement(element) {

        findAndInit(element, true, false);

    }


    /**
     * Process all eligible children of an element.
     */
    function processChildren(element) {

        findAndInit(element, false, true);

    }


    /**
     * Process an element and all eligible children.
     */
    function process(element) {

        findAndInit(element, true, true);

    }


    // Export public API
    mtl.alloy = $.extend(instance, {
        process: process,
        processElement: processElement,
        processChildren: processChildren,
        on: $emitter.on.bind($emitter),
        off: $emitter.off.bind($emitter),
    });

}(jQuery, window.mtl = window.mtl || {}));
