;(function($, alloy) {
    'use strict';

    /**
     * Checks that the given options object is valid.
     */
    function checkOptions(options) {

        if (!options.changeAction) {
            throw new Error('changeAction is required');
        }

        return options;

    }


    /**
     * Initialises the diary table.
     */
    alloy.diaryTable = function (element, options) {

        options = checkOptions(options || {});


        /**
         * Merges the given diary-table into the current view.
         */
        function mergeTable($table) {

            var $body = $(element).find('tbody').first();

            var newRows = [];

            $table.find('tbody tr').each(function (i, newRow) {

                // Note that the current algorithm is limited to only replacing rows and appending rows.

                var date = newRow.getAttribute('data-date');

                var oldRow = $body.find('tr[data-date="' + date + '"]')[0];
                if (oldRow && (newRow.getAttribute('data-hash') + '') === (oldRow.getAttribute('data-hash') + '')) {
                    return;
                }

                mtl.alloy.process(newRow);

                newRows.push(newRow);

                var $newRow = $(newRow);

                if (oldRow) {
                    $newRow.replaceAll(oldRow);
                }
                else {
                    $newRow.appendTo($body);
                }

            });

            app.markUpdated(newRows);

        }


        /**
         * Looks for a diary-table in the given content and, if found, merges it with the current view.
         */
        function findAndMergeTable($content) {

            var $table = $content.filter('.diary-table');
            if ($table.length < 1) {
                $table = $content.find('.diary-table');
            }

            if ($table.length < 1) {
                return false;
            }

            mergeTable($table);
            return true;

        }


        /**
         * Handles modal beforeShow events.
         */
        function onModalBeforeShow(event) {

            var $content = $(event.dialog);

            // If the content contains a diary-table, merge it with the current view and then close the modal
            if (findAndMergeTable($content)) {
                event.preventDefault();
            }

        }


        /**
         * Starts changing the event on a given date.
         */
        function changeEvent(date, changeTo) {

            var $tr = $(element).find('tr[data-date="' + date + '"]');
            var $select = $tr.find('select[name="change"]');
            var $td = $select.closest('td');

            if ($tr.hasClass('is-loading')) {
                return;
            }

            $tr.addClass('is-loading');
            $td.addClass('is-loading');

            var select = $select[0];
            if (select && select === document.activeElement) {
                select.blur();
            }

            var url = options.changeAction.replace('0000-00-00', date);

            $.ajax(url, {
                cache: false,
                data: {
                    type: $select.attr('data-type'),
                    to: changeTo
                },
                dataType: 'html',
                method: 'POST',

                /**
                 * On success.
                 */
                success: function (content) {

                    var $content = $(util.parseHTMLBody(content));

                    // If the response contains a diary-table, merge it with the current view
                    if (findAndMergeTable($content)) {
                        return;
                    }

                    // Otherwise, show the content in a modal
                    else {
                        mtl.alloy.process($content);
                        app.modal.open($content);
                        bindModalListeners();
                    }

                },

                /**
                 * On error.
                 */
                error: function () {

                    // @fixme Display an error message

                },

                /**
                 * On complete.
                 */
                complete: function () {

                    $tr.removeClass('is-loading');
                    $td.removeClass('is-loading');

                    select.selectedIndex = 0;

                },

            });

        }


        /**
         * Binds common listeners to the modal dialog.
         */
        function bindModalListeners() {

            app.modal
                .on('beforeShow', onModalBeforeShow)
                .on('deactivate', unbindModalListeners)
            ;

        }


        /**
         * Removes common listeners from the modal dialog.
         */
        function unbindModalListeners() {

            app.modal
                .off('beforeShow', onModalBeforeShow)
                .off('deactivate', unbindModalListeners)
            ;

        }


        // Listen for changes to the event status dropdowns
        $(element).on('change', 'select[name="change"]', function (event) {

            var select = event.currentTarget;

            var changeTo = select.options[select.selectedIndex].value + '';
            if (!changeTo) {
                return;
            }

            var date = $(select).closest('[data-date]').attr('data-date');
            if (!date) {
                return;
            }

            changeEvent(date, changeTo);

        });

        // Listen for elements that want to open a modal dialog
        $(element).on('modal.opener', bindModalListeners);

    };

})(jQuery, mtl.alloy.factory);
