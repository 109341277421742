;(function($, alloy) {
    'use strict';

    var PROTOTYPE_ATTR = 'data-prototype';


    var defaultOptions = {

        // The file upload URL
        uploadUrl: null,

        // Enable or disable support for multiple uploads (optional)
        // Leave as to auto-detected by inspecting the file input element
        multiple: null,

        // An array listing the recognised file types
        // e.g. [ "png", "gif", "jpg" ]
        knownFileTypes: [],

        // An object mapping file type aliases to their recognised file types
        // e.g. { "jpeg": "jpg" }
        fileTypeAliases: {},

        // The default file type to assume if the given type is not recognised
        defaultFileType: 'default'

    };


    /**
     * Initialises the element as an async file uploader.
     */
    var fileUpload = alloy.fileUpload = function (element, options) {

        options = $.extend({}, defaultOptions, options);

        var $element = $(element).first();
        element = $element[0];

        // Ensure that the uploadUrl is set
        if (options.uploadUrl == null) {
            console.error('uploadUrl is required');
            return;
        }

        // Find important elements
        var $dropZone = $element.find('.upload-drop-zone').first();
        var $uploadInput = $element.find('input[type="file"]').first();
        var $fileList = $element.find('.upload-file-list').first();

        // Find/update the multiple-upload option
        if (options.multiple == null) {
            options.multiple = $uploadInput.prop('multiple');
        }
        else {
            $uploadInput.prop('multiple', !!(options.multiple));
        }

        // Find the file collection prototype
        var prototype = $element
            .find('[' + PROTOTYPE_ATTR + ']')
            .addBack('[' + PROTOTYPE_ATTR + ']')
            .attr(PROTOTYPE_ATTR)
        ;

        if (prototype == null || prototype === '') {
            console.error('Could not find ' + PROTOTYPE_ATTR + ' attribute');
            return;
        }

        // Runtime variables
        var nextIndex = $fileList.children().length;
        var fileCount = nextIndex;
        var activeUploads = [];


        /**
         * Finds the file element that corresponds to the given upload handle.
         */
        function findElementForHandle(handle) {

            for (var i = 0, ix = activeUploads.length; i < ix; i++) {

                var activeUpload = activeUploads[i];
                if (activeUpload.handle === handle) {
                    return activeUpload.element;
                }

            }

            return null;

        }


        /**
         * Finds an upload handle that corresponds to the given file element.
         */
        function findHandleForElement(element) {

            for (var i = 0, ix = activeUploads.length; i < ix; i++) {

                var activeUpload = activeUploads[i];
                if (activeUpload.element === element) {
                    return activeUpload.handle;
                }

            }

            return null;

        }


        /**
         * Removes an upload handle from the active uploads list.
         */
        function removeHandle(handle) {

            for (var i = 0, ix = activeUploads.length; i < ix; i++) {

                var activeUpload = activeUploads[i];
                if (activeUpload.handle === handle) {
                    activeUploads.splice(i, 1);
                    return;
                }

            }

        }


        /**
         * Tries to infer the file type from the given file name.
         */
        function getFileTypeFromName(name) {

            name += '';

            var p = name.lastIndexOf('.');
            if (p === -1) {
                return 'unknown';
            }

            var type = name.substr(p + 1).toLowerCase();

            if (options.fileTypeAliases[type] != null) {
                type = options.fileTypeAliases[type] + '';
            }

            var foundIndex = options.knownFileTypes.indexOf(type);
            if (foundIndex > -1) {
                return options.knownFileTypes[foundIndex];
            }

            return options.defaultFileType;

        }


        /**
         * Creates a file element for the given upload handle.
         */
        function createFileElement(handle) {

            var fileName = handle.files[0].name;
            var fileType = getFileTypeFromName(fileName);

            return util.parseHTML(prototype
                .replace(/__file_name__/g, util.encodeEntities(fileName))
                .replace(/__file_type__/g, fileType)
                .replace(/__index__/g, nextIndex++)
            )[0];

        }


        /**
         * Sets the progress of the given file element.
         */
        function setProgress(fileElement, progress) {

            progress = Math.max(0.00001, Math.min(+progress, 1));

            $(fileElement).find('.upload-file__progress').css('transform', 'scaleX(' + progress + ')');

        }


        /**
         * Sets the input UUID of the given file element.
         */
        function setFileUuid(fileElement, uuid) {

            $(fileElement).find('input[type="hidden"]').val(uuid);

        }


        /**
         * Updates the status classes on the top-level container element.
         */
        function updateContainerStatus() {

            $element.toggleClass('has-files', (fileCount > 0));

        }


        /**
         * Starts a file upload.
         */
        function startUpload(handle) {

            // @todo Check that the file is of an accepted type
            // @todo Check that the file does not exceed the max size limit

            if (!options.multiple && fileCount > 0) {
                return false;
            }

            var fileElement = createFileElement(handle);

            setProgress(fileElement, 0);

            $(fileElement)
                .addClass('will-appear')
                .delay(33)
                .queue(function (next) {
                    $(this).removeClass('will-appear');
                    next();
                })
            ;

            app.quickStatus.loading(fileElement);

            $fileList.append(fileElement);

            fileCount++;
            activeUploads.push({
                element: fileElement,
                handle: handle
            });

            updateContainerStatus();

        }


        /**
         * Updates the progress of an upload.
         */
        function onProgress(handle) {

            var fileElement = findElementForHandle(handle);
            if (!fileElement) {
                return;
            }

            var progress = (handle.total > 0) ?
                handle.loaded / handle.total :
                0;

            setProgress(fileElement, progress);

        }


        /**
         * Handles a successful upload.
         */
        function onDone(handle) {

            var fileElement = findElementForHandle(handle);
            if (!fileElement) {
                return;
            }

            var result = handle.result;

            // @todo Check that the upload actually succeeded

            setFileUuid(fileElement, result.data.uuid);
            setProgress(fileElement, 1);

            app.quickStatus.success(fileElement).resetAfter(fileElement, 800);

            removeHandle(handle);

        }


        /**
         * Handles upload failure.
         */
        function onFail(handle) {

            if (handle.isAborted === true) {
                return;
            }

            var fileElement = findElementForHandle(handle);
            if (!fileElement) {
                return;
            }

            alert('Upload failed: ' + handle.errorThrown);

            fileCount--;
            removeHandle(handle);
            removeFileElement(fileElement);
            updateContainerStatus();

        }


        /**
         * Removes a file from the list.
         */
        function removeFile(fileElement, needConfirmation) {

            if (needConfirmation == null) {
                needConfirmation = true;
            }

            var handle = findHandleForElement(fileElement);
            if (handle) {

                var xhr = handle.xhr();
                if (xhr.readyState < 4) {
                    needConfirmation = false;
                    handle.isAborted = true;
                    xhr.abort();
                }

            }

            if (!needConfirmation || confirm('Are you sure you want to remove this file?')) {
                fileCount--;
                removeHandle(handle);
                removeFileElement(fileElement);
                updateContainerStatus();
            }

        }


        /**
         * Removes a file element from the list.
         */
        function removeFileElement(fileElement) {

            $(fileElement).find('input').remove();

            $(fileElement)
                .stop(true, true)
                .addClass('will-remove')
                .delay(300)
                .queue(function (next) {
                    $(this).remove();
                    next();
                })
            ;

        }


        // Listen to events on the file upload widget
        $uploadInput.fileupload({

            url:         options.uploadUrl,
            autoUpload:  true,
            dataType:    'json',
            dropZone:    $dropZone,

            send: function (event, handle) {
                startUpload(handle);
            },

            progress: function (event, handle) {
                onProgress(handle);
            },

            done: function (event, handle) {
                onDone(handle);
            },

            fail: function (event, handle) {
                onFail(handle);
            },

        });

        // Listen to clicks on the file remove button
        $element.on('click', '.upload-file__remove', function (event) {
            var fileElement = $(event.currentTarget).closest('.upload-file')[0];
            removeFile(fileElement);
        });

    };


    // Export default options
    fileUpload.defaultOptions = defaultOptions;

})(jQuery, mtl.alloy.factory);
