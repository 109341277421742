;(function($, alloy) {
    'use strict';

    var defaultOptions = {

        // Lookup URL
        url: null,

        // VRM input
        vrm: null,

        // Vehicle manufacturer input (optional)
        manufacturer: null,

        // Vehicle model input (optional)
        model: null,

        // Additional info input (optional)
        info: null,

    };


    /**
     * Initialises an element as a vehicle-lookup button.
     */
    var vehicleLookup = alloy.vehicleLookup = function (button, options) {

        options = $.extend({}, defaultOptions, options);

        if (options.url == null) {
            console.error('url is required');
            return;
        }

        var activeRequest = null;


        /**
         * Get the vehicle lookup form.
         */
        function getForm() {

            return $(button).closest('form')[0];

        }


        /**
         * Get an input.
         */
        function getInput(find, form) {

            if (find instanceof HTMLElement) {
                return find;
            }

            if (form == null) {
                form = getForm();
            }

            var input;

            if ((input = form.elements.namedItem(find + ''))) {
                return input;
            }

            if ((input = $(form).find(find + '')[0])) {
                return input;
            }

            return null;

        }


        /**
         * Performs a lookup using the value of the VRM input field.
         */
        function doLookup() {

            var form = getForm();
            if (form == null) {
                console.error('Could not find containing form for %o', button);
                return;
            }

            var vrmInput = getInput(options.vrm, form);
            if (vrmInput == null) {
                console.error('Could not find VRM input');
                return;
            }

            var vrm = (vrmInput.value + '').trim();
            if (vrm === '') {
                alert('Please enter the vehicle registration mark.');
                return;
            }

            var request = $.ajax(options.url, {
                cache: false,
                method: 'POST',
                dataType: 'json',
                data: {
                    vrm: vrm
                },
            });

            activeRequest = request;

            $(button).addClass('is-loading').prop('disabled', true);

            // On success
            request.done(function (response) {

                if (request !== activeRequest) {
                    return;
                }

                if (response.success) {
                    onSuccess(form, response.data, options);
                }

                else {
                    onError(form, options);
                }

            });

            // On error
            request.fail(function () {

                if (request !== activeRequest) {
                    return;
                }

                onError(form, options);

            });

            // On complete
            request.always(function () {

                if (request !== activeRequest) {
                    return;
                }

                activeRequest = null;

                $(button).removeClass('is-loading').prop('disabled', false);

            });

        }


        /**
         * Handles a successful lookup.
         */
        function onSuccess(form, data, options) {

            var props = [
                'vrm',
                'manufacturer',
                'model',
                'info',
            ];

            for (var i = 0, ix = props.length; i < ix; i++) {

                var prop = props[i];

                var inputName = options[prop];
                if (inputName == null) {
                    continue;
                }

                var input = getInput(inputName, form);
                if (!input) {
                    continue;
                }

                var oldValue = (input.value + '');
                var newValue = (data[prop] != null) ? data[prop] : '';

                if (newValue !== oldValue) {
                    input.value = newValue;
                    $(input).trigger('change');
                }

            }

        }


        /**
         * Handles a failed lookup.
         */
        function onError(form, options) {

            alert('No information was found for this vehicle.');

        }


        // Listen for clicks on the button
        $(button).on('click', function (event) {
            doLookup();
        });

    };


    // Export default options
    vehicleLookup.defaultOptions = defaultOptions;

}(jQuery, mtl.alloy.factory));
