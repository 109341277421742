;(function($, alloy) {
    'use strict';

    var factory = alloy.input = (alloy.input || {});


    /**
     * Find and submit the closest form.
     */
    function onAutoSubmitChange(event) {

        var input = event.currentTarget;

        // Ignore the placeholder option of <select> inputs
        if (input.tagName === 'SELECT' && input.options[input.selectedIndex].hasAttribute('data-placeholder')) {
            return;
        }

        // Find and submit the closest form
        var $form = $(input).closest('form');
        if ($form.length) {
            $form.trigger('submit');
        }

    }


    /**
     * Finds the index of the select option that matches the given value.
     */
    function findSelectIndexWithValue(select, value) {

        if (value == null) {
            return null;
        }

        var options = select;
        for (var i = 0, ix = options.length; i < ix; ++i) {
            if (options[i].value === value) {
                return i;
            }
        }

        return null;

    }


    /**
     * Automatically submits the form when any input changes.
     */
    factory.autoSubmitAll = function(element, options) {

        var inputSelector = (options && options.filter) || 'input, select, textarea';

        $(element).on('change', inputSelector, onAutoSubmitChange);

    };


    /**
     * Automatically submits the form when the input changes.
     */
    factory.autoSubmit = function(element) {

        $(element).on('change', onAutoSubmitChange);

    };


    /**
     * Adds a button to clear the input value.
     */
    factory.clearable = function(input, options) {

        var $input = $(input);

        // Check that the input is inside an form-input element
        var $wrapper = $input.parent().closest('.form-input');
        if ($wrapper.length < 1) {
            console.error('Could not find form-input container for clearable input %o', input);
            return;
        }

        // Define the default options
        var valueProperty = 'value';
        var defaultValue = $input.attr('data-default-value') || '';

        // Special handling for <select> inputs
        if (input.tagName === 'SELECT') {
            valueProperty = 'selectedIndex';
            defaultValue = findSelectIndexWithValue(input, defaultValue) || 0;
        }

        /**
         * Gets the current value.
         */
        function getCurrentValue() {

            return $input.prop(valueProperty);

        }

        /**
         * Updates the `is-clearable` state on the wrapper element.
         */
        function updateClearableState() {

            var clearable = (getCurrentValue() !== defaultValue);
            fastdom.mutate(function() {
                $wrapper.toggleClass('is-clearable', clearable);
            });

        }

        /**
         * Clears the current value.
         */
        function clearValue() {

            if (getCurrentValue() === defaultValue) {
                return;
            }

            var event = jQuery.Event('clear');
            $input.triggerHandler(event);

            if (event.isDefaultPrevented()) {
                return;
            }

            $input.prop(valueProperty, defaultValue).trigger('change');

        }

        // Listen for input changes and update the clearable state
        $input.on('change', updateClearableState);

        // Add the clear button to the input
        fastdom.mutate(function() {

            $(util.parseHTML('<button type="button" class="form-input__clear"></button>')[0])
                .append(app.svgicon('admin/cross-circ'))
                .on('click', clearValue)
                .appendTo($wrapper)
            ;

            updateClearableState();

        });

    };


    /**
     * Makes a <select> input update window.location when changed.
     */
    factory.selectWindowLocation = function (input, options) {

        options = options || {};

        $(input).on('change', function (event) {

            var $input = $(event.currentTarget);

            var addr = $input.val();
            if (!addr) {
                return;
            }

            if (
                (options.modal === 'opener' || options.modal === true) ||
                (options.modal === 'reuser' && app.modal.contains($input))
            ) {
                app.modal.open(addr, { type: 'ajax' });
            }

            else {
                window.location = addr;
            }

        });

    };


    /**
     * Makes a <select> input update the action of the closest form when changed.
     */
    factory.selectFormAction = function (input, options) {

        $(input).on('change', function (event) {

            var $input = $(event.currentTarget);

            var addr = $input.val();
            if (!addr) {
                return;
            }

            $input.closest('form').attr('action', addr);

        });

    };

}(jQuery, mtl.alloy.factory));
