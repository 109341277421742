;(function($, alloy) {
    'use strict';

    /**
     * Initialises the filters form.
     */
    alloy.filterForm = function(element, options) {

        var $form = $(element);

        // Get the target selector
        var targetSelector = options && options.target;
        if (!targetSelector) {
            console.error('target is required');
            return;
        }


        /**
         * Submits the form by AJAX request.
         */
        function submitFormAJAX () {

            var $old = $(targetSelector).first();

            // Fade-out the current table
            $old
                .stop(true, false)
                .css('pointer-events', 'none')
                .animate({ opacity: 0.3 }, 200, 'easieEaseOut')
            ;

            // AJAX-y submit
            $.ajax({
                type:  $form.attr('method'),
                url:   $form.attr('action'),
                data:  $form.serialize()
            })
            .done(function(response) {

                var $content = $(util.parseHTMLBody(response));
                app.pushState.fromContent($content);
                app.pageUpdate.fromContent($content);

                var $new = $(targetSelector).first();

                $new
                    .stop(true, false)
                    .css('pointer-events', '')
                    .css('opacity', $old.stop(true, false).css('opacity'))
                    .animate({ opacity: 1 }, 200, 'easieEase', function() {
                        $(this).css('opacity', '');
                    })
                ;

            });

        }


        // Create a debounced version of submitFormAJAX
        var submitFormAJAXDebounced = util.debounce(submitFormAJAX, 50);

        // Catch submit event
        $form.on('submit', function(event) {
            event.preventDefault();
            submitFormAJAXDebounced();
        });


        /**
         * Clears the value of the given input.
         */
        function clearInput($input, defaultValue, valueProperty) {

            var event = jQuery.Event('clear');
            $input.triggerHandler(event);

            if (event.isDefaultPrevented()) {
                return;
            }

            $input.prop(valueProperty, defaultValue).trigger('change');

        }


        // Clear all filters
        $form.on('click', '.filter-bar__clear', function() {

            var didChange = false;

            $form.find('select').each(function(i, select) {
                if (select.selectedIndex !== 0) {
                    clearInput($(select), 0, 'selectedIndex');
                    didChange = true;
                }
            });

            $form.find('input[type!="hidden"]').each(function(i, input) {
                if ((input.value + '').trim() !== '') {
                    clearInput($(input), '', 'value');
                    didChange = true;
                }
            });

            if (didChange) {
                $form.trigger('submit');
            }

        });

        // Make <select> inputs clearable and auto-submitting
        $form.find('.filter-bar__filter-group select').each(function(i, element) {
            alloy.input.autoSubmit(element);
            alloy.input.clearable(element);
        });

        // Make text inputs clearable and auto-submitting
        $form.find('.filter-bar__search-group input').each(function (i, element) {
            alloy.input.autoSubmit(element);
            alloy.input.clearable(element);
        });

        // Make date picker inputs clearable and auto-submitting
        $form.find('.jqueryDateRange').each(function(i, element) {
            alloy.input.autoSubmit(element);
            alloy.input.clearable(element);
        });

        // Make all hidden inputs auto-submitting
        $form.find('input[type="hidden"]').each(function(i, element) {
            alloy.input.autoSubmit(element);
        });

    };

}(jQuery, mtl.alloy.factory));
