;(function($, alloy) {
    'use strict';

    /**
     * Triggers an automatic file download prompt.
     */
    alloy.startDownload = function (element, options) {

        var href = element.href;
        if (!href) {
            return;
        }

        var delay = (options.delay != null) ? +options.delay : 200;

        setTimeout(function () {

            var iframe = document.createElement('iframe');
            iframe.className = 'visuallyhidden';
            iframe.src = href;
            $(iframe).appendTo(document.body);

            setTimeout(function () {
                $(iframe).remove();
            }, 10000);

        }, delay);

    };

})(jQuery, mtl.alloy.factory);
