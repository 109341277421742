;(function($, alloy) {
    'use strict';

    /**
     * Initialises a table for filtering.
     */
    alloy.filterTable = function(table, options) {

        var formName = (options && options.formName) || null;
        if (!formName) {
            console.error('No formName specified for filterable table %o', table);
            return;
        }

        var $table = $(table);


        /**
         * Gets the filter form orderBy input.
         */
        function getOrderByInput() {

            return $('#' + formName + '_orderBy');

        }


        /**
         * Gets the filter form orderDir input.
         */
        function getOrderDirInput() {

            return $('#' + formName + '_orderDir');

        }


        // Attach an order-by-arrow element to each eligible column heading
        $table.find('th[filter-order-by]').each(function(i, th) {

            var orderBy = $(th).attr('filter-order-by');
            var currentOrderBy, currentOrderDir;

            fastdom.measure(function() {
                currentOrderBy = getOrderByInput().val();
                currentOrderDir = getOrderDirInput().val();
            });

            fastdom.mutate(function() {

                var isActive = (orderBy === currentOrderBy);

                $(util.parseHTML('<span class="order-by-arrow"></button>')[0])
                    .toggleClass('is-active', isActive)
                    .toggleClass('is-desc', isActive && (currentOrderDir === 'DESC'))
                    .append(app.svgicon('admin/sort-arrow'))
                    .appendTo(th)
                ;

            });

        });

        // Listen for click events on
        $table.on('click', 'th[filter-order-by]', function(event) {

            var orderBy = $(event.currentTarget).attr('filter-order-by');

            var $orderByInput = getOrderByInput();
            var currentOrderBy = $orderByInput.val();

            var $orderDirInput = getOrderDirInput();
            var currentOrderDir = $orderDirInput.val();

            // Change orderDir
            if (orderBy === currentOrderBy) {
                var orderDir = (currentOrderDir !== 'ASC') ? 'ASC' : 'DESC';
                $orderDirInput.val(orderDir).trigger('change');
            }

            // Change orderBy
            else {
                $orderByInput.val(orderBy).trigger('change');
                if (currentOrderDir !== 'ASC') {
                    $orderDirInput.val('ASC').trigger('change');
                }
            }

        });

    };

}(jQuery, mtl.alloy.factory));
