;(function ($, alloy) {
    'use strict';

    var VALUE_WAS_ATTR = 'data-value-was';


    /**
     * Gets the live value of the given input.
     */
    function getLiveValue(input) {

        return $(input).val();

    }


    /**
     * Gets the stored value of the given input.
     */
    function getStoredValue(input) {

        return input.getAttribute(VALUE_WAS_ATTR);

    }


    /**
     * Tests whether the given input has a stored value.
     */
    function hasStoredValue(input) {

        return input.hasAttribute(VALUE_WAS_ATTR);

    }


    /**
     * Sets the live value of the given input.
     */
    function setLiveValue(input, value) {

        $(input).val(value);

        var hidden = getHiddenSibling(input);
        if (hidden) {
            hidden.value = value;
        }

    }


    /**
     * Sets the stored value of the given input.
     */
    function setStoredValue(input, value) {

        input.setAttribute(VALUE_WAS_ATTR, value);

    }


    /**
     * Removes the stored value from the given input.
     */
    function removeStoredValue(input) {

        input.removeAttribute(VALUE_WAS_ATTR);

    }


    /**
     * Sets the live value from the stored value, then optionally removes the stored value from the given input.
     */
    function restoreValue(input, andRemove) {

        if (hasStoredValue(input)) {
            setLiveValue(input, getStoredValue(input));
            if (andRemove) {
                removeStoredValue(input);
            }
        }

    }


    /**
     * Gets the hidden-input sibling of the given element, if it has one.
     */
    function getHiddenSibling(input) {

        var hidden = input.nextSibling;
        if (hidden && hidden.type === 'hidden' && hidden.name === input.name) {
            return hidden;
        }

        return null;

    }


    /**
     * Locks the given input to prevent editing.
     */
    function lockInput(input) {

        input.disabled = true;

        if (input.tagName !== 'SELECT') {
            input.readOnly = true;
        }

        var hidden = util.parseHTML('<input type="hidden" />')[0];
        hidden.name = input.name;
        hidden.value = getLiveValue(input);

        $(hidden).insertAfter(input);

    }


    /**
     * Unlocks the given input to reallow editing.
     */
    function unlockInput(input) {

        var hidden = getHiddenSibling(input);
        if (hidden) {
            $(hidden).remove();
        }

        if (input.tagName !== 'SELECT') {
            input.readOnly = false;
        }

        input.disabled = false;

    }


    /**
     * Initialises an element as a copy-values toggle.
     */
    alloy.copyValuesToggle = function initCopyValues(checkbox, options) {

        // Expects the following options:
        //     scope:
        //         Selector targeting the scope element
        //         (optional, defaults to "form")
        //     target:
        //         Selector targeting the inputs eligible for copy-to-all functionality
        //         (optional, defaults to all input-like elements)
        //     groupAttr:
        //         Name of the attribute used for grouping the inputs
        //         (optional, defaults to null)

        var scope = $(checkbox).closest(options.scope || 'form').get(0);
        if (scope == null) {
            console.error('Could not find scope %o from %o', options.scope, checkbox);
            return;
        }

        var isActive = false;

        var groupElements = {};
        var groupUpdateFn = {};


        /**
         * Updates the value of inputs in the given group.
         */
        function updateGroup(name) {

            var elements = groupElements[name + ''];

            var firstValue = getLiveValue(elements[0]);

            for (var i = 1, ix = elements.length; i < ix; i++) {

                var input = elements[i];

                if (!hasStoredValue(input)) {
                    setStoredValue(input, getLiveValue(input));
                }

                setLiveValue(input, firstValue);

            }

        }


        /**
         * Updates the inputs in all groups.
         */
        function updateAll() {

            for (var groupName in groupElements) {
                updateGroup(groupName);
            }

        }


        /**
         * Activates copy-to-all functionality.
         */
        function activate() {

            if (isActive) {
                return;
            }

            isActive = true;

            var targetSelector = options.target || 'input, select, textarea';
            var groupAttr = options.groupAttr || null;

            $(scope).find(targetSelector).each(function (i, input) {

                if (input === checkbox) {
                    return;
                }

                var groupName = (groupAttr) ?
                    (input.getAttribute(groupAttr) + '') :
                    '_';

                if (groupElements[groupName] == null) {
                    groupElements[groupName] = [input];
                    $(input).on('input paste change', (groupUpdateFn[groupName] = updateGroup.bind(null, groupName)));
                }
                else {
                    groupElements[groupName].push(input);
                    lockInput(input);
                }

            });

            updateAll();

        }


        /**
         * Deactivates copy-to-all functionality.
         */
        function deactivate() {

            if (!isActive) {
                return;
            }

            isActive = false;

            $.each(groupElements, function (groupName, elements) {

                $(elements[0]).off('input paste change', groupUpdateFn[groupName]);

                for (var i = 1, ix = elements.length; i < ix; i++) {
                    var input = elements[i];
                    unlockInput(input);
                    restoreValue(input, true);
                }

            });

            groupElements = {};
            groupUpdateFn = {};

        }


        /**
         * Toggles on/off the copy-to-all functionality.
         */
        function updateActivation() {

            if (checkbox.checked) {
                activate();
            }

            else {
                deactivate();
            }

        }


        // Listen to changes on the checkbox
        $(checkbox).on('change', updateActivation);

        // Immediately update the activation state
        updateActivation();

    };

}(jQuery, mtl.alloy.factory));
