window.mtl = window.mtl || {};
window.mtl.lson = (function() {
    'use strict';

    /**
     * A pattern for tokenising an LSON string.
     *
     * @type  {RegExp}
     */
    var tokenizerPattern = /([:,\[\]\{\}]+|(?:"[^"\\]*(?:\\.[^"\\]*)*")|[^\s":,\[\]\{\}]+)/gi;


    /**
     * A pattern for testing whether a token is JSON-quoted.
     *
     * @type  {RegExp}
     */
    var isQuotedNamePattern = /^(?:[:,\[\]\{\}]+|".*)$/;


    /**
     * A pattern for testing whether a token is JSON-quoted.
     *
     * @type  {RegExp}
     */
    var isQuotedValuePattern = /^(?:null|true|false|[:,\[\]\{\}]+|".*|(?:-?[0-9]+(?:\.[0-9]+)?(?:[eE][\+\-][0-9]+)?))$/;


    /**
     * Quote an LSON string for JSON parsing.
     *
     * @param  {string}  str  An un-quoted string.
     *
     * @return  {string}  A JSON-compliant quoted string.
     */
    function lsonQuote(str) {

        var i, ix, token;
        var tokens = (str + '').match(tokenizerPattern);

        if (!tokens) {
            return '';
        }

        for (i = 0, ix = tokens.length; i < ix; i++) {

            if ((token = tokens[i])) {

                if (tokens[i + 1] === ':') {
                    if (isQuotedNamePattern.test(token)) {
                        continue;
                    }
                }

                else if (isQuotedValuePattern.test(token)) {
                    continue;
                }

                tokens[i] = '"' + token + '"';

            }

        }

        return tokens.join('');

    }


    /**
     * Parse an LSON string as an object.
     *
     * @param  {string}  str  The LSON string to parse.
     * @param  {boolean}  isQuoted  True to indicate that the string is already quoted.
     *
     * @return  {Object}
     */
    function lsonParseObject(str, isQuoted) {

        var quoted = isQuoted ? str : lsonQuote(str);

        return JSON.parse(
            (quoted.substr(0, 1) !== '{') ? '{' + quoted + '}' : quoted
        );

    }


    /**
     * Parse an LSON string as an array.
     *
     * @param  {string}  str  The LSON string to parse.
     * @param  {boolean}  isQuoted  True to indicate that the string is already quoted.
     *
     * @return  {Array}
     */
    function lsonParseArray(str, isQuoted) {

        var out, quoted = isQuoted ? str : lsonQuote(str);

        try {
            return Array.isArray(out = JSON.parse(quoted)) ? out : [out];
        }

        catch (error) {
            return JSON.parse('[' + quoted + ']');
        }

    }


    /**
     * Parse an LSON string as an object or array.
     *
     * @param  {string}  str  The LSON string to parse.
     * @param  {boolean}  isQuoted  True to indicate that the string is already quoted.
     *
     * @return  {Object|Array}
     */
    function lsonParseObjectOrArray(str, isQuoted) {

        var quoted = isQuoted ? str : lsonQuote(str);

        // Try to parse as an object
        try {
            return lsonParseObject(quoted, true);
        }

        // Parse as an array
        catch (error) {
            return lsonParseArray(quoted, true);
        }

    }


    /**
     * Parse an LSON string to a value.
     *
     * @param  {string}  str  The JSON-like string to be parsed.
     *
     * @return  {*}
     */
    function lsonParse(str) {

        return JSON.parse(lsonQuote(str));

    }

    // Export public API
    return {
        parse:               lsonParse,
        parseObject:         lsonParseObject,
        parseArray:          lsonParseArray,
        parseObjectOrArray:  lsonParseObjectOrArray
    };

})();
