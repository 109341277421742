;(function($, alloy) {
    'use strict';

    /**
     * Initialises the element as a payment type select.
     */
    alloy.selectPaymentType = function (select, options) {

        var transactionFormSelector = '[data-input="orderCode"]';
        var deductibleFormSelector = '[data-input="deductible"]';


        /**
         * Shows the given form.
         */
        function showForm(form, instant) {

            $(form)
                .stop(true, false)
                .addClass('is-active')
                .css({
                    opacity: 0,
                    display: '',
                })
                .animate({
                    opacity: 1,
                }, 200, 'easieEase')
            ;

            if (instant === true) {
                $(form).stop(true, true);
            }

        }


        /**
         * Hides the given form.
         */
        function hideForm(form, instant) {

            $(form)
                .stop(true, false)
                .removeClass('is-active')
                .css({
                    display: 'none',
                })
            ;

        }


        /**
         * Toggles the visibility of the given form.
         */
        function toggleFormVisibility(form, toggle, instant) {

            if (toggle == null) {
                toggle = !($(form).hasClass('is-active'));
            }

            if (toggle === true) {
                showForm(form, instant);
            }

            else {
                hideForm(form, instant);
            }

        }


        /**
         * Toggles validation on the given input.
         */
        function toggleInputValidation(input, toggle) {

            var noValidate = input.formNoValidate = (toggle != null) ?
                !(toggle) :
                !!(input.formNoValidate);

            if (noValidate && input.required) {
                input.setAttribute('data-required', 'required');
                input.required = false;
            }

            if (!noValidate && input.getAttribute('data-required')) {
                input.required = true;
            }

        }


        /**
         * Updates the visibility of the transaction form.
         */
        function updateTransactionInput(instant) {

            var $select = $(select);
            var $subform = $select.closest('form').find(transactionFormSelector);

            var val = $select.val();
            var setActive = (val === 'virtual terminal');

            var isActive = $subform.hasClass('is-active');
            if (isActive === setActive && instant !== true) {
                return;
            }

            toggleFormVisibility($subform, setActive, instant);

            $subform.find('input, select').each(function (i, input) {
                toggleInputValidation(input, setActive);
            });

        }


        /**
         * Updates the visibility of the cash deduction form.
         */
        function updateDeductibleToggle(instant) {

            var $select = $(select);
            var $subform = $select.closest('form').find(deductibleFormSelector);

            var val = $select.val();
            var setActive = (val === 'cash');

            var isActive = $subform.hasClass('is-active');
            if (isActive === setActive && instant !== true) {
                return;
            }

            toggleFormVisibility($subform, setActive, instant);

        }


        /**
         * Updates the visibility of all sub-forms.
         */
        function update(instant) {

            updateTransactionInput(instant);
            updateDeductibleToggle(instant);

        }


        // Update when the payment type dropdown is changed
        $(select).on('change', util.debounce(update, 1));

        // Immediately update
        update(true);

    };

})(jQuery, mtl.alloy.factory);
