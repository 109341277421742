;(function($, alloy) {
    'use strict';

    var defaultOptions = {

        // ...
        target: 'tr',

    };


    /**
     * Initialises a button to toggle sibling table rows on/off when clicked.
     */
    alloy.toggleTableRows = function (element, options) {

        options = $.extend({}, defaultOptions, options || {});

        var isActive = false;
        var $hiddenRows = $();


        /**
         * Updates the visibility of the target rows.
         */
        function update() {

            var $myRow = $(element).closest('tr')
                .toggleClass('is-expanded', isActive)
            ;

            if (isActive) {

                $hiddenRows.insertAfter($myRow);
                $hiddenRows = $();

            }

            else {

                $hiddenRows = $hiddenRows.add(
                    $myRow.siblings(options.target).detach()
                );

            }

        }


        /**
         * Toggles the visibility.
         */
        function toggle(setActive) {

            setActive = (setActive != null) ?
                !!setActive :  // toggle set explictly
                !isActive;     // inferred from current state

            if (setActive === isActive) {
                return;
            }

            isActive = setActive;
            update();

        }


        $(element).on('click', function (event) {
            event.preventDefault();
            toggle();
        });

        update();

    };

})(jQuery, mtl.alloy.factory);
