mtl.alloy.factory.toggleDetails = (function($) {
    'use strict';

    return function (element, options) {

        var $element = $(element);
        var $dialog = $element.parents().last();
        var $toggleTarget = $dialog.find(options.toggleTarget);
        var $toggleIcon = $element.find('.icon');

        $toggleTarget.hide();
        $element.on('click', function(e) {
            e.preventDefault();

            $toggleIcon.toggleClass('is-active');
            $toggleTarget.stop(true, false).slideToggle();
        });

    };

}(jQuery));
