/* globals google */
;(function($, alloy) {
    'use strict';

    var defaultOptions = {

        // Google Maps API key - required
        googleMapsApiKey: null,

        // latitude of the map centre - required
        lat: null,

        // longitude of the map centre - required
        lng: null,

        // map zoom level - not required, default 8
        zoom: 8,

        // whether or not to show a pin at the center - default false
        showMarker: false

    };

    var isLoading = false;

    var isReady = false;

    var toRender = [];


    /**
     * Render a GMaps widget.
     */
    function render(element, options) {

        // Google Maps needs to calculate the size of the container so, before rendering, we need to test whether the
        // map is actually on the page. If not, we wait a little while and then try again.
        var rect = element.getBoundingClientRect();
        if (rect.width < 1 && rect.height < 1) {
            setTimeout(render.bind(null, element, options), 200);
            return;
        }


        var center = {lat: options.lat, lng: options.lng};

        var map = new google.maps.Map(element, {
            center: center,
            zoom: options.zoom
        });

        if (options.showMarker) {
            new google.maps.Marker({
                position: center,
                map: map
            });
        }

        $(element).trigger('mapReady', [ map ]);

    }


    /**
     * Callback on loading the GMaps library.
     */
    function onLoad() {

        isReady = true;

        for (var i = 0, ix = toRender.length; i < ix; i++) {
            var item = toRender[i];
            render(item[0], item[1]);
        }

        toRender = null;

    }


    window.gmapOnload=onLoad;


    function loadLibrary(googleMapsApiKey) {

        if (isLoading) {
            return;
        }

        var script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsApiKey + '&callback=gmapOnload';
        document.getElementsByTagName('head')[0].appendChild(script);
        isLoading = true;

    }


    var insertMap = alloy.insertMap = function (element, options) {

        options = $.extend( {}, defaultOptions, options||{} );

        var googleMapsApiKey = options.googleMapsApiKey;
        if (!googleMapsApiKey) {
            console.error('googleMapsApiKey is required');
            return;
        }

        var lat = options.lat;
        if (typeof lat !== 'number') {
            console.error('lat is required');
            return;
        }

        var lng = options.lng;
        if (typeof lng !== 'number') {
            console.error('lng is required');
            return;
        }

        var zoom = parseInt(options.zoom);
        if (isNaN(zoom)) {
            zoom = 8;
        }

        var showMarker = options.showMarker;
        if (showMarker) {
            showMarker = true;
        }
        else {
            showMarker = false;
        }

        var renderOptions = {
            lat: lat,
            lng: lng,
            zoom: zoom,
            showMarker: showMarker
        };

        // Ready to render
        if (isReady/* && window.grecaptcha*/) {
            render(element, renderOptions);
        }

        // Wait for library to load
        else {
            loadLibrary(googleMapsApiKey);
            toRender.push([element, renderOptions]);
        }

    };


    // Export default options
    insertMap.defaultOptions = defaultOptions;

}(jQuery, mtl.alloy.factory));
