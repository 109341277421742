;(function($, alloy) {
    'use strict';

    /**
     * Makes a <select> input replace in-page content when changed.
     */
    alloy.selectRemoteContent = function (select, options) {

        // Expects the following options:
        //     scope:
        //         Selector targeting the scope element
        //         (optional, defaults to "form")
        //     target:
        //         Selector targeting the inputs eligible for copy-to-all functionality
        //         (optional, defaults to all input-like elements)

        var scopeSelector = options.scope || 'body';

        var targetSelector = options.target;
        if (targetSelector == null) {
            console.error('target is required');
            return;
        }

        var activeRequest = null;

        // Listen for changes to the <select>...
        $(select).on('change', function (event) {

            var addr = $(select).val();
            if (!addr) {
                return;
            }

            var $scope = $(select).closest(scopeSelector);
            if ($scope.length === 0) {
                console.error('Could not find scope %o from %o', scopeSelector, select);
                return;
            }

            var $target = $scope.find(targetSelector);
            if ($target.length === 0) {
                console.error('Could not find target %o from %o', targetSelector, $scope);
                return;
            }

            // Fetch the new content with an async request
            var request = $.ajax(addr, {
                cache: false,
            });

            activeRequest = request;

            // Fade-out the target element
            $target
                .stop(true, false)
                .css('pointer-events', 'none')
                .animate({ opacity: 0.3 }, 200, 'easieEaseOut')
            ;

            // Once the request is successful...
            request.done(function (content) {

                if (request !== activeRequest) {
                    return;
                }

                var $content = $(util.parseHTMLBody(content));

                // Find the replacing element
                $content = $content.find(targetSelector).addBack(targetSelector).first();
                if ($content.length === 0) {
                    console.error('Could not find target %o from %o', targetSelector, $content);
                    return;
                }

                $content.css('opacity', $target.stop(true, false).css('opacity'));

                // If the scope is a form, update the action
                if ($scope.is('form')) {
                    $scope.prop('action', $content.closest('form').prop('action'));
                }

                // Replace the target element with the replacing element
                mtl.alloy.process($content);
                $target.replaceWith($content);
                $target = $content;

            });

            // Once the request has finished...
            request.always(function () {

                if (request !== activeRequest) {
                    return;
                }

                activeRequest = null;

                // Fade-in the target element
                $target
                    .stop(true, false)
                    .css('pointer-events', '')
                    .animate({ opacity: 1 }, 200, 'easieEase', function() {
                        $(this).css('opacity', '');
                    })
                ;

            });

        });

    };

})(jQuery, mtl.alloy.factory);
