mtl.alloy.factory.orderSummary = (function($) {
    'use strict';

    return function (element, options) {

        var $element = $(element);

        var $buyForms = $('form[name="buy_consumable"]');

        function mergeContent(content) {
            var $content = $(content);
            $element.empty().append($content.children());
            mtl.alloy.processChildren($element);
        }

        // Add consumables to order via AJAX
        $buyForms.each(function() {
            $(this).on('submit', function(e) {

                $.ajax({
                    type:  $(this).attr('method'),
                    url:   $(this).attr('action'),
                    data:  $(this).serialize(),
                    success: function(response) {
                        mergeContent(response);
                    }
                });

                e.preventDefault();

            });
        });

        function updateQuantities() {

            $.ajax({
                type:  $element.attr('method'),
                url:   $element.attr('action'),
                data:  $element.serialize() + '&update=update',
                success: function(response) {
                    mergeContent(response);
                }
            });

        }

        var updateQuantitiesDebounced = util.debounce(updateQuantities, 500);

        // Update form prices on quantity change
        $element.on('input', '.quantity-input', function(e) {

            updateQuantitiesDebounced();

        });

        // Don't submit form when pressing enter inside a quantity input
        $element.on('keypress', '.quantity-input', function(e) {

            if (e.which === 13) {
                e.preventDefault();
            }

        });

        // Remove orderline items from order via AJAX
        $element.on('click', '.remove-link', function(e) {

            $.ajax({
                type:  'post',
                url:   this.href,
                data:  $element.serialize(),
                success: function(response) {
                    mergeContent(response);
                }
            });

            e.preventDefault();

        });

        // Clear the order summary button via AJAX
        $element.on('click', '.button--type-cancel', function(e) {

            $.ajax({
                type:  $element.attr('method'),
                url:   $element.attr('action'),
                data:  $element.serialize() + '&clear=clear',
                success: function(response) {
                    mergeContent(response);
                }
            });

            e.preventDefault();

        });

    };

}(jQuery));
