;(function($, alloy) {
    'use strict';

    /**
     * Initialises the element as a ticket reissue form.
     */
    alloy.reissueTicketForm = function (element, options) {

        var activeRequest = null;

        var lookupUrl = $(element).attr('data-price-lookup');


        function updateRepairCost() {

            var request = $.ajax(lookupUrl, {
                cache:     false,
                method:    'GET',
                dataType:  'json',
                data: {
                    insurer: $(element).find('select[data-input="insurer"]').val(),
                },
            });

            activeRequest = request;

            request.done(function (response) {

                if (request !== activeRequest) {
                    return;
                }

                if (!response.success || response.data == null || response.data.amount == null) {
                    return;
                }

                var amount = response.data.amount | 0;

                console.warn();

                $(element).find('input[data-input="repairCost"]').val((amount * 0.01).toFixed(2));

            });

        }


        $(element).on('change', 'select[data-input="insurer"]', updateRepairCost);

    };

})(jQuery, mtl.alloy.factory);
