;(function($, alloy) {
    'use strict';

    var factory = alloy.checkbox = (alloy.checkbox || {});


    // Toggles the class of a parent element
    // ---------------------------------------------------------

    (function() {

        var DEFAULT_TOGGLE_CLASS = 'is-checked';


        /**
         * Updates the visual state of a checkbox parent.
         */
        function updateParentState(input, parentSelector, toggleClass) {

            var $input = $(input);

            var isChecked = !!(input.checked);

            (parentSelector ? $input.closest(parentSelector) : $input.parent())
                .toggleClass(toggleClass, isChecked)
            ;

        }


        /**
         * Initialises parent-toggling behaviour on the given checkbox.
         */
        function initParentToggle(input, options) {

            var parentSelector = options.parent || null;
            var toggleClass = options.toggleClass || DEFAULT_TOGGLE_CLASS;

            $(input).on('change', updateParentState.bind(null, input, parentSelector, toggleClass));

            updateParentState(input, parentSelector, toggleClass);

            options = null;

        }


        /**
         * Initialises parent-toggling behaviour on all checkboxes inside the given container.
         */
        function initParentToggles(container, options) {

            var $container = $(container);

            var inputSelector = options.inputSelector || 'input[type="checkbox"]';
            var parentSelector = options.parent || null;
            var toggleClass = options.toggleClass || DEFAULT_TOGGLE_CLASS;

            $container.on('change', inputSelector, function(event) {
                updateParentState(event.currentTarget, parentSelector, toggleClass);
            });

            $container.find(inputSelector).each(function(i, input) {
                updateParentState(input, parentSelector, toggleClass);
            });

            options = null;

        }


        /**
         * Initialises parent-toggling behaviour on the given checkbox or container.
         */
        function initToggleParentClass(element, options) {

            if (element.tagName === 'INPUT') {
                initParentToggle(element, options);
            }

            else {
                initParentToggles(element, options);
            }

        }


        // Export public API
        factory.toggleParentClass = initToggleParentClass;

    }());


    // Toggles the checked state of all related inputs
    // ---------------------------------------------------------

    (function() {

        /**
         * Toggles the checked state of all inputs.
         */
        function toggleAll(scope, targetSelector, checked, ignore) {

            var checkedNow = !!(checked);

            $(scope).find(targetSelector).each(function(i, other) {

                if (other === ignore) {
                    return;
                }

                var checkedWas = !!(other.checked);
                if (checkedNow !== checkedWas) {
                    other.checked = checkedNow;
                    $(other).trigger('change');
                }

            });

        }


        /**
         * Updates the checked state of a toggle-all input.
         */
        function updateToggleAllState(input, scope, targetSelector) {

            var $others = $(scope).find(targetSelector);

            var checkedWas = !!(input.checked);
            var checkedNow = false;

            for (var i = 0, ix = $others.length; i < ix; i++) {

                var other = $others[i];

                if (other === input) {
                    continue;
                }

                if (other.checked !== true) {
                    checkedNow = false;
                    break;
                }

                checkedNow = true;

            }

            if (checkedNow !== checkedWas) {
                input.checked = checkedNow;
                $(input).trigger('change');
            }

        }


        /**
         * Initialises toggle-all behaviour on the given checkbox.
         */
        function initToggleAll(input, options) {

            var scope = $(input).closest(options.scope).get(0);
            if (scope == null) {
                console.error('Could not find scope %o from %o', options.scope, input);
                return;
            }

            var targetSelector = options.target || 'input[type="checkbox"]';

            $(input).on('click', function(event) {
                toggleAll(scope, targetSelector, input.checked, input);
            });

            $(scope).on('click', targetSelector, function() {
                updateToggleAllState(input, scope, targetSelector);
            });

            updateToggleAllState(input, scope, targetSelector);

            options = null;

        }


        // Export public API
        factory.toggleAll = initToggleAll;

    }());


}(jQuery, mtl.alloy.factory));
