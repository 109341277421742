/* globals svg4everybody */
;(function($) {
    'use strict';

    // On document ready...
    $(document).ready(function() {

        // Run svg4everybody
        var svg4everybodyOpts = {};
        if (/\bEdge\/.(\d+)\b/.test(navigator.userAgent)) {
            svg4everybodyOpts.polyfill = true; // Fix transition perf in Edge
        }
        svg4everybody(svg4everybodyOpts);

        // Process alloy directives when content is opened in a modal
        app.modal.on('afterParse', function (event) {
            mtl.alloy.process(event.content);
        });

        // Process alloy directives
        mtl.alloy.initAttr = 'mtl-init';
        mtl.alloy.process(document.body);

    });

}(jQuery));
