;(function($, alloy) {
    'use strict';

    /**
     * Initialises the element as a tooltip container.
     */
    alloy.tooltip = function (element, options) {

        $(element).on('click', function (event) {

            event.preventDefault();

            var tooltip = document.createElement('DIV');
            tooltip.className = 'tooltip will-appear';
            tooltip.innerText = options.content;
            document.body.append(tooltip);

            var $tooltip = $(tooltip);

            var placement = (options && options.placement) || 'top';
            var popper = Popper.createPopper(element, tooltip, {
                placement: placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12],
                        },
                    },
                ],
                onFirstUpdate: function (wooz) {
                    requestAnimationFrame(function () {
                        $tooltip.removeClass('will-appear');
                    });
                },
            });

            function remove() {

                if ($tooltip.hasClass('will-appear') || $tooltip.hasClass('will-remove')) {
                    return;
                }

                $(document.body).off('click', remove);

                $tooltip.addClass('will-remove');
                setTimeout(function () {
                    popper.destroy();
                    $tooltip.remove();
                }, 300);

            }

            $(document.body).on('click', remove);

            setTimeout(remove, 3000);

        });

    };

})(jQuery, mtl.alloy.factory);
