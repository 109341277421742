;(function($, alloy) {
    'use strict';

    /**
     * Initialises the element as a quick-status container.
     */
    alloy.quickStatus = function (element, options) {

        // Create an instance object
        var instance = {
            element: element
        };

        // Attach quick-status methods to the instance
        [
            'loading',
            'success',
            'error',
            'reset',
            'resetAfter',
        ].forEach(function (name) {

            instance[name] = function (arg) {

                var fn = app.quickStatus[name];

                fn(element, arg);

                return instance;

            };

        });

        // Return instance API
        return instance;

    };

})(jQuery, mtl.alloy.factory);
