;(function($, alloy) {
    'use strict';

    var defaultOptions = {

        pageUpdate: true,

    };


    /**
     * Initialises the element as a action-bar form.
     */
    alloy.actionBarForm = function (form, options) {

        options = $.extend({}, defaultOptions, options || {});

        var $form = $(form).first();

        var activeRequest = null;

        // Find the quick-status element, if present
        var status = (function () {
            var $status = $form.find('.quick-status').first();
            return ($status[0]) ? alloy.quickStatus($status) : null;
        }());


        /**
         * Asynchronously submits the form.
         */
        function submitForm() {

            var data = $form.serializeArray();

            var request = $.ajax($form.prop('action'), {
                cache:   false,
                method:  $form.prop('method'),
                data:    $.param(data),
            });

            activeRequest = request;

            if (status) {
                status.loading();
            }

            // On success
            request.done(function (response) {

                if (request !== activeRequest) {
                    return;
                }

                if (status) {
                    status.success();
                }

                if (options.pageUpdate) {
                    app.pageUpdate.refresh();
                }

                if (response.includes('class="dialog')) {
                    app.modal.open(response);
                }

            });

            // On error
            request.fail(function () {

                if (request !== activeRequest) {
                    return;
                }

                if (status) {
                    status.error();
                }

            });

            // On complete
            request.always(function () {

                if (request !== activeRequest) {
                    return;
                }

                activeRequest = null;

                if (status) {
                    status.resetAfter(800);
                }

            });

        }


        // Listen for form submit events
        $(form).on('submit', util.lateEventHandler(function (event) {

            // Note that we use a lateEventHandler because we need to give the action-bar time
            // to populate our list of selected items.

            if (event.isDefaultPrevented()) {
                return;
            }

            submitForm();
            event.preventDefault();

        }));

    };

})(jQuery, mtl.alloy.factory);
