window.util = window.util || {};
window.util.lateEventHandler = (function($) {
    'use strict';

    /**
     * Finds the root parent of the given node.
     */
    function findRootNode(node) {

        var parentNode;

        while ((parentNode = node.parentNode)) {
            node = parentNode;
        }

        return node;

    }


    /**
     * Binds an event listener to the root element to execute a callback after bubbling has finished.
     */
    function bindLateEvent(scope, args, then) {

        var initialEvent = args[0];
        var initialEventType = initialEvent.type;

        var rootNode = findRootNode(initialEvent.currentTarget);

        // Define a root-node event handler
        var rootListener = function (rootEvent) {

            if (initialEvent.originalEvent !== rootEvent.originalEvent) {
                return;
            }

            then.apply(scope, args);

        };

        // @todo Do we need to handle cases where the initial node *is* the root node?

        // Attach the handler as a one-time listener to the root node
        $(rootNode).one(initialEventType, rootListener);

        // Manually remove the handler in case it wasn't automatically cleared (e.g. due to stopPropagation)
        setTimeout(function () {
            $(rootNode).off(initialEventType, rootListener);
        }, 0);

    }


    /**
     * Causes the given event handler to be deferred until all event bubbling has finished.
     */
    function lateEventHandler(then) {

        return function () {

            var args = [];
            for (var i = 0, ix = arguments.length; i < ix; i++) {
                args[i] = arguments[i];
            }

            bindLateEvent(this, args, then);

        };

    }


    // Export public API
    return lateEventHandler;

}(jQuery));
