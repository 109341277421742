;(function($, alloy) {
    'use strict';

    /**
     * Initialises an anchor as an unread-note dismiss button.
     */
    alloy.readNoteButton = function (element, options) {

        $(element).on('click', function (event) {

            var $element = $(event.currentTarget);

            // Notify the server that the note was dismissed
            $.ajax({
                type: 'POST',
                url:  $element.attr('href'),
            });

            // Hide the "Read?" button
            var $parent = $element.parent();
            $parent
                .css({
                    overflow: 'hidden',
                    width: $parent.outerWidth(),
                })
                .animate({
                    opacity: 0,
                    width: 0,
                }, 300, 'easieEase')
                .queue(function (next) {
                    $element.remove();
                    next();
                })
            ;

            event.preventDefault();

        });

    };

})(jQuery, mtl.alloy.factory);
