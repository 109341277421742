/* globals moment */
;(function($, alloy) {
    'use strict';

    var futurePresetRanges = [{
        text: 'Today',
        dateStart: function () { return moment(); },
        dateEnd: function () { return moment(); }
    }, {
        text: 'Tomorrow',
        dateStart: function () { return moment().add(1, 'days'); },
        dateEnd: function () { return moment().add(1, 'days'); }
    }, {
        text: 'Next 7 Days',
        dateStart: function () { return moment(); },
        dateEnd: function () { return moment().add(6, 'days'); }
    }, {
        text: 'Next Week',
        dateStart: function () { return moment().add(1, 'weeks').startOf('isoWeek'); },
        dateEnd: function () { return moment().add(1, 'weeks').endOf('isoWeek'); }
    }, {
        text: 'To Month End',
        dateStart: function () { return moment(); },
        dateEnd: function () { return moment().endOf('month'); }
    }, {
        text: 'Next Month',
        dateStart: function () { return moment().add(1, 'months').startOf('month'); },
        dateEnd: function () { return moment().add(1, 'months').endOf('month'); }
    }, {
        text: 'To Year End',
        dateStart: function () { return moment(); },
        dateEnd: function () { return moment().endOf('year'); }
    }];

    /**
     * Initialises an input as a date range picker.
     */
    alloy.dateRangePicker = function(element, options) {
        var $element = $(element);
        var dateRangeSettings = {
            initialText: '- Date range -',
            datepickerOptions: {
                numberOfMonths: 2,
                firstDay: 1
            },
            change: function () {
                $(this).siblings('.comiseo-daterangepicker-triggerbutton').addClass('removeArrow');
                $(this).siblings('.form-input__clear').show();
            },
            clear: function () {
                $(this).siblings('.comiseo-daterangepicker-triggerbutton').removeClass('removeArrow');
                $(this).siblings('.form-input__clear').hide();
            }
        };

        if ($element.attr('data-future-dates')) {
            dateRangeSettings.presetRanges = futurePresetRanges;
            dateRangeSettings.datepickerOptions = $.extend(dateRangeSettings.datepickerOptions || {}, {
                maxDate: null,
            });
        }

        $(element).daterangepicker(dateRangeSettings);

        $(element).on('clear', function(event) {
            event.preventDefault();
            $(element).daterangepicker('clearRange');
        });
    };

}(jQuery, mtl.alloy.factory));
