window.mtl = window.mtl || {};
window.mtl.path = (function() {
    'use strict';


    /**
     * Parse the given path into tokens ready for walking.
     *
     * @param   {string|array}  path  The path, as an array or dot-notated string.
     *
     * @return  {array}  The path as an array.
     */
    function pathParse(path) {

        // @todo  Add support for character escaping.

        var out;

        if ((path == null) || (path === '')) { // Path is empty.
            out = [];
        }

        else {
            out = (Array.isArray(path)) ?
                path.slice(0) :
                (path + '').split('.');
        }

        out.toString = function() {
            return this.join('.');
        };

        return out;

    }


    /**
     * Walk a path from an origin object and execute a callback on completion.
     *
     * If provided, the onSuccess callback is executed on successfully reaching the end of the path and the value it
     * returns will become the return value of the walk operation. Otherwise, the return value will be the value found
     * at the end of the path. See {@link mtl.path~walkSuccessCallback} for implementation details.
     *
     * Should the walk operation fail, the return value will always be <code>undefined</code>.
     *
     * @param   {object}  origin  The origin object to begin walking from.
     * @param   {string|array}  path  The path to walk, as an array or dot-notated string.
     * @param   {?mtl.path~walkSuccessCallback}  [onSuccess]  A callback function to execute upon finishing walking.
     *
     * @return  {*}  The value returned by the onSuccess callback or, if not provided, the value found at the end of the
     * path.
     */
    function pathWalk(origin, path, onSuccess) {

        /**
         * A callback function to be invoked on successfully resolving a {@link mtl.path.walk} operation.
         *
         * Any value returned by the callback will become the return value of the walk operation.
         *
         * @callback mtl.path~walkSuccessCallback
         *
         * @param   {*}  value  The value found at the end of the path.
         * @param   {*}  context  The parent context from which the value was retrieved.
         * @param   {string}  token  The property name used to retrieve the value.
         * @param   {array}  path  The full path as used by the walk operation.
         *
         * @return  {*}  Any value.
         */

        path = pathParse(path);

        var i, ix, token, context, tokenTitleCase,
            value = origin;

        for (i = 0, ix = path.length; i < ix; i++) {

            if (value == null || typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string') {
                i = -1; // Infer that the walk loop did not finish
                break;
            }

            token = path[i];
            context = value;

            tokenTitleCase = token.substr(0, 1).toUpperCase() + token.substr(1);

            value = context[token];
            if (typeof value === 'undefined' && typeof context.__get === 'function') {
                value = context.__get(token);
            }

        }

        if (i === ix) {
            return onSuccess ?
                onSuccess(value, context, token, path) :
                value;
        }

        return undefined;

    }


    /**
     * Get a value by walking a path from an origin object.
     *
     * @param   {object}  origin  The origin object to begin walking from.
     * @param   {string|array}  path  The path to walk, as an array or dot-notated string.
     *
     * @return  {object}  The path result.
     */
    function pathGet(origin, path) {

        return pathWalk(origin, path);

    }


    /**
     * Set a value by walking a path from an origin object.
     *
     * @param   {object}  origin  The origin object to begin walking from.
     * @param   {string|array}  path  The path to walk, as an array or dot-notated string.
     * @param   {*}  value  The value to set.
     */
    function pathSet(origin, path, value) {

        path = pathParse(path);
        if (path.length === 0) {
            throw new Error('Can not set a value on a zero-length path');
        }

        var lastToken = path.pop();
        pathWalk(origin, path, function(context) {

            if (
                context != null &&
                !(typeof context === 'boolean' || typeof context === 'number' || typeof context === 'string')
            ) {

                var setter;
                if ((setter = context.__set)) {
                    setter.call(context, lastToken, value);
                }
                else {
                    context[lastToken] = value;
                }

            }

        });

        // @todo  Can we provide a useful return value?

    }


    /**
     * Execute a function by walking a path from an origin object, using arguments provided as an array.
     *
     * When the function is executed, <code>this</code> will be the previous path context. That is, given the path
     * <code>'foo.bar.func'</code>, <code>this</code> will be <code>bar</code>.
     *
     * An exception to the above is that, if the path is empty and the origin is a function, it will be executed with
     * <code>this</code> being <code>undefined</code> (or <code>window</code>, if not in strict mode).
     *
     * This function shares similar semantics to the native Function.prototype.apply() method.
     *
     * @see mtl.path.call
     *
     * @param   {object}  origin  The origin object to begin walking from.
     * @param   {string|array}  path  The path to walk, as an array or dot-notated string.
     * @param   {?array}  [args]  The arguments to pass to the function, as an array or array-like object.
     *
     * @return  {*}  The return value of the function, or undefined if no function was executed.
     */
    function pathApply(origin, path, args) {

        path = pathParse(path);

        if (args == null) {
            args = [];
        }

        if (path.length === 0) {
            return (typeof origin === 'function') ?
                origin.apply(undefined, args) :
                undefined;
        }

        return pathWalk(origin, path, function(value, context) {

            if (typeof value === 'function') {
                return value.apply(context, args);
            }

        });

    }


    /**
     * Execute a function by walking a path from an origin object, using arguments provided individually.
     *
     * When the function is executed, <code>this</code> will be the previous path context. That is, given the path
     * <code>'foo.bar.func'</code>, <code>this</code> will be <code>bar</code>.
     *
     * An exception to the above is that, if the path is empty and the origin is a function, it will be executed with
     * <code>this</code> being <code>undefined</code> (or <code>window</code>, if not in strict mode).
     *
     * This function shares similar semantics to the native Function.prototype.call() method.
     *
     * @see mtl.path.apply
     *
     * @param   {object}  origin  The origin object to begin walking from.
     * @param   {string|array}  path  The path to walk, as an array or dot-notated string.
     * @param   {...*}  arg  An argument to pass to the function.
     *
     * @return  {*}  The return value of the function, or undefined if no function was executed.
     */
    function pathCall(origin, path) {

        return pathApply(origin, path, Array.prototype.slice.call(arguments, 2));

    }


    // Export public API
    return {
        walk:  pathWalk,
        get:   pathGet,
        set:   pathSet,
        call:  pathCall,
        apply: pathApply,
    };

})();
