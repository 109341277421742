/* globals pca */
;(function($, alloy) {
    'use strict';

    var defaultOptions = {

        // PCA account code
        pcaAccountCode: null,

    };


    var isPcaInserted = false;


    /**
     * Initialises an element as an address-lookup field.
     */
    var addressLookup = alloy.addressLookup = function (element, options) {

        options = $.extend({}, defaultOptions, options);

        if (options.pcaAccountCode) {
            var pcaAccountCode = options.pcaAccountCode;

            // Add PCA's code element
            if (!isPcaInserted) {
                (function(n,t,i,r){var u,f;n[i]=n[i]||{},n[i].initial={accountCode:pcaAccountCode,host:pcaAccountCode + ".pcapredict.com"},n[i].on=n[i].on||function(){(n[i].onq=n[i].onq||[]).push(arguments)},u=t.createElement("script"),u.async=!0,u.src=r,f=t.getElementsByTagName("script")[0],f.parentNode.insertBefore(u,f)})(window,document,"pca","//" + pcaAccountCode + ".pcapredict.com/js/sensor.js")
                isPcaInserted = true;
            }

            // We need to re-call pca.load() when a modal launches, to find form(s) in it.
            // BUT when NOT in a modal, pca.load() does not exist, so check for it first.
            else if (window.pca && typeof pca.load === 'function') {
                pca.load();
            }
        }
        else {
            console.error('pcaAccountCode is required');
        }

    };


    // Export default options
    addressLookup.defaultOptions = defaultOptions;

}(jQuery, mtl.alloy.factory));
