;(function() {
    'use strict';

    var util = window.util = (window.util || {});

    // Regular Expressions for parsing tags and attributes
    var SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

    // Match everything outside of normal chars and " (quote character)
    var NON_ALPHANUMERIC_REGEXP = /([^#-~ |!])/g;


    /**
     * Escapes all potentially dangerous characters, so that the
     * resulting string can be safely inserted into attribute or
     * element text.
     *
     * @see  https://github.com/angular/angular.js/blob/master/src/ngSanitize/sanitize.js
     */
    util.encodeEntities = function (value) {

        return value
            .replace(/&/g, '&amp;')
            .replace(SURROGATE_PAIR_REGEXP, function(value) {
                var hi = value.charCodeAt(0);
                var low = value.charCodeAt(1);
                return '&#' + (((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000) + ';';
            })
            .replace(NON_ALPHANUMERIC_REGEXP, function(value) {
                return '&#' + value.charCodeAt(0) + ';';
            })
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
        ;
    };

}());
