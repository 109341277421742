/* globals URLSearchParams */
;(function ($) {
    'use strict';

    var app = window.app = (window.app || {});


    /**
     * Gets the parameters currently applied to the page.
     */
    function getCurrentParams() {

        return new URLSearchParams(location.search);

    }


    /**
     * Pushes the given parameters to the URL.
     */
    function pushParams(params) {

        var currentParams = getCurrentParams();

        var iterator = params.entries();

        var value;
        while ((value = iterator.next().value)) {
            currentParams.set(value[0], value[1]);
        }

        var url = location.pathname + '?' + currentParams.toString();
        if (location.hash) {
            url += location.hash;
        }

        history.replaceState(null, document.title, url);

    }


    /**
     * Pushes the given arguments to the URL.
     */
    function pushArgs(args) {

        var params = new URLSearchParams($.param(args));
        pushParams(params);

    }


    /**
     * Decodes an LSON string into an arguments object.
     */
    function decodeArgs(value) {

        try {
            return mtl.lson.parseObject(value);
        }

        catch (error) {
        }

        return null;

    }


    /**
     * Extracts parameters from the given content and applies them to the URL.
     */
    function extractFrom(element) {

        var found = false;
        var args = {};

        $(element).find('[data-push-args]').addBack('[data-push-args]').each(function (i, element) {

            var myArgs = decodeArgs(element.getAttribute('data-push-args'));
            if (myArgs) {
                $.extend(args, myArgs);
                found = true;
            }

        });

        if (!found) {
            return;
        }

        pushArgs(args);

    }


    // Export public API
    app.pushState = {
        extractFrom: extractFrom,
        fromContent: extractFrom,
    };

})(jQuery);
