;(function($, alloy) {
    'use strict';

    alloy.dashboardViewSelect = function(select, options) {

        $(select).on('change', function () {

            var formName = this.form.name;

            $('#' + formName + '_orderBy').val('name');
            $('#' + formName + '_orderDir').val('ASC');

        });

    };

}(jQuery, mtl.alloy.factory));
