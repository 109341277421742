;(function($, alloy) {
    'use strict';

    /**
     * Initialises the element as a correspondence details box.
     */
    alloy.correspondenceDetails = function (element, options) {

        var modal = app.modal;

        var $element = $(element);

        var $activeFormBody = null;

        var dialogTemplate = $element.attr('data-dialog-template');


        /**
         * Opens a modal dialog to start editing the document details.
         */
        function startEditing() {

            // To enable editing inside a modal, we create a new dialog form and then attach our
            // details sub-form to it

            bindModalEvents();

            var $dialog = $(util.parseHTML(dialogTemplate));
            mtl.alloy.process($dialog);

            $activeFormBody = $element.find('.id-correspond-detail-form');
            $dialog.toggleClass('dialog--narrow', $activeFormBody.find('.form-grid__body').length < 2);
            $dialog.find('.dialog__body').append($activeFormBody);

            var $originalForm = $element.closest('form');

            $dialog.first() // We assume that the dialog itself is the form
                .prop('action', $originalForm.prop('action'))
                .prop('method', $originalForm.prop('method'))
            ;

            modal.open($dialog);

        }


        /**
         * Finds correspondence details in the given content and merges them into our own element.
         */
        function findAndMergeDetails($content) {

            var didMatch = false;

            var selectors = [
                '.id-correspond-detail-preview',
                '.id-correspond-detail-form',
            ];

            selectors.forEach(function (selector) {

                var $newElement = $content.find(selector);
                if (!$newElement.length) {
                    return;
                }

                var $oldElement = $element.find(selector);
                if (!$oldElement.length) {
                    return;
                }

                $oldElement.replaceWith($newElement);

                didMatch = true;

            });

            return didMatch;

        }


        /**
         * Handles modal afterLoad events.
         */
        function onModalAfterLoad(event) {

            // Convert the content to DOM elements
            var $content = event.content = $(
                (typeof event.content === 'string') ? util.parseHTMLBody(event.content) : event.content
            );

            // Find details in the response and merge them into our own content
            if (findAndMergeDetails($content)) {
                event.preventDefault(); // Block the modal
            }

        }


        /**
         * Handles modal beforeRemove events.
         */
        function onModalBeforeRemove(event) {

            // If the dialog being removed contains our details sub-form, we want to reclaim it

            if (!$activeFormBody.length) {
                return;
            }

            if (!$.contains(event.dialog[0], $activeFormBody[0])) {
                return;
            }

            var $form = $element.find('.id-correspond-detail-form');
            if ($form.length) {
                return;
            }

            $element.append($activeFormBody);

        }


        /**
         * Binds modal events.
         */
        function bindModalEvents() {

            modal
                .on('afterLoad', onModalAfterLoad)
                .on('beforeRemove', onModalBeforeRemove)
                .on('afterDeactivate', unbindModalEvents)
            ;

        }


        /**
         * Unbinds modal events.
         */
        function unbindModalEvents() {

            modal
                .off('afterLoad', onModalAfterLoad)
                .off('beforeRemove', onModalBeforeRemove)
                .off('afterDeactivate', unbindModalEvents)
            ;

        }


        // Listen for clicks on the edit button
        $(element).on('click', '.id-correspond-detail-edit', function (event) {
            startEditing();
            event.preventDefault();
        });

    };

})(jQuery, mtl.alloy.factory);
