;(function ($) {
    'use strict';

    var QUEUE_NAME = 'qs';

    var app = window.app = (window.app || {});


    /**
     * Sets to loading state.
     */
    function loading(element) {

        $(element)
            .stop(QUEUE_NAME, true, false)
            .removeClass('status-success status-error')
            .addClass('status-loading')
        ;

        return app.quickStatus;

    }


    /**
     * Sets to success state.
     */
    function success(element) {

        $(element)
            .stop(QUEUE_NAME, true, false)
            .removeClass('status-loading status-success')
            .addClass('status-success')
        ;

        return app.quickStatus;

    }


    /**
     * Sets to error state.
     */
    function error(element) {

        $(element)
            .stop(QUEUE_NAME, true, false)
            .removeClass('status-loading status-error')
            .addClass('status-error')
        ;

        return app.quickStatus;

    }


    /**
     * Resets the state to default.
     */
    function reset(element) {

        $(element)
            .stop(QUEUE_NAME, true, false)
            .removeClass('status-loading status-success status-error')
        ;

        return app.quickStatus;

    }


    /**
     * Resets the state to default after the given delay.
     */
    function resetAfter(element, duration) {

        $(element)
            .stop(QUEUE_NAME, true, false)
            .delay(+duration, QUEUE_NAME)
            .queue(QUEUE_NAME, function (next) {
                app.quickStatus.reset(this);
                next();
            })
            .dequeue(QUEUE_NAME)
        ;

        return app.quickStatus;

    }


    // Export public API
    app.quickStatus = {
        loading: loading,
        success: success,
        error: error,
        reset: reset,
        resetAfter: resetAfter,
    };

})(jQuery);
