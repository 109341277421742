;(function($, alloy) {
    'use strict';

    /**
     * Initialises the element as a ticket details bar.
     */
    alloy.ticketDetails = function (element, options) {


        /**
         * Toggle the collapsed state.
         */
        function toggleCollapsed() {

            var $element = $(element).stop(true, false);

            // Expand
            if ($element.hasClass('is-collapsed')) {

                $element
                    .css('height', $element.outerHeight())
                    .removeClass('is-collapsed')
                    .delay(33)
                    .queue(function (next) {
                        $element.css('height', $element.children().first().outerHeight());
                        next();
                    })
                    .delay(400)
                    .queue(function (next) {
                        $element.css('height', '');
                        next();
                    })
                ;

            }

            // Collapse
            else {

                $element
                    .css('height', $element.outerHeight())
                    .addClass('is-collapsed')
                    .delay(33)
                    .queue(function (next) {
                        $element.css('height', '');
                        next();
                    })
                ;

            }

        }


        // Listen for click events on the container
        $(element).on('click', function (event) {

            if ($(event.target).closest('a, button, input, label').length) {
                return;
            }

            toggleCollapsed();

        });

    };

})(jQuery, mtl.alloy.factory);
