;(function ($) {
    'use strict';

    var FRAGMENT_ATTRIBUTE = 'data-fragment-ref';
    var FRAGMENT_SELECTOR = '[' + FRAGMENT_ATTRIBUTE + ']';

    var HASH_ATTRIBUTE = 'data-fragment-hash';

    var app = window.app = (window.app || {});


    /**
     * Find replaceable fragments within the given element.
     */
    function findFragments($element) {

        var out = {};

        $element
            .filter(FRAGMENT_SELECTOR)
            .add($element.find(FRAGMENT_SELECTOR))
            .each(function (i, element) {
                out[element.getAttribute(FRAGMENT_ATTRIBUTE)] = element;
            })
        ;

        return out;

    }


    /**
     * Find matching pairs of replaceable fragments within the given existing and new elements.
     */
    function findFragmentPairs($old, $new) {

        var oldNodes = findFragments($old);
        var newNodes = findFragments($new);

        var allNodes = [];

        for (var ref in newNodes) {

            var oldNode = oldNodes[ref];
            if (oldNode) {

                var newNode = newNodes[ref];

                allNodes.push({
                    newHash:  newNode.hasAttribute(HASH_ATTRIBUTE) ? newNode.getAttribute(HASH_ATTRIBUTE) : newNode.outerHTML,
                    newNode:  newNode,
                    oldHash:  oldNode.hasAttribute(HASH_ATTRIBUTE) ? oldNode.getAttribute(HASH_ATTRIBUTE) : oldNode.outerHTML,
                    oldNode:  oldNode,
                    ref:      ref,
                });

            }

        }

        return allNodes;

    }


    /**
     * Swap the two HTML nodes.
     */
    function swapNodes(node1, node2) {

        var parent1 = node1.parentNode;
        var parent2 = node2.parentNode;

        var stub1;
        if (parent1) {
            stub1 = document.createElement(node1.nodeName);
            parent1.insertBefore(stub1, node1);
        }

        if (parent2) {
            parent2.insertBefore(node1, node2);
        }
        else if (parent1) {
            parent1.removeChild(node1);
        }

        if (parent1) {
            parent1.insertBefore(node2, stub1);
        }
        else if (parent2) {
            parent2.removeChild(node2);
        }

        if (stub1) {
            parent1.removeChild(stub1);
        }

    }


    /**
     * Gets an event instance.
     */
    function getEvent(type, props) {

        var event = $.Event(type);

        if (props != null) {
            $.extend(event, props);
        }

        return event;

    }


    /**
     * Refresh page fragments using the given content.
     */
    function fromContent(content) {

        var rootNode = document.body;

        // Parse the content into elements
        var $content = $(
            (typeof content === 'string') ? util.parseHTMLBody(content.trim()) : content
        );

        // Create an array for recording newly-added elements
        var addedElements = [];

        // Find and iterate over the existing replaceable elements...
        var pairs = findFragmentPairs($(rootNode), $content);
        for (var i = 0, ix = pairs.length; i < ix; i++) {

            var pair = pairs[i];

            // Decide which node to keep
            var keepNode = (pair.newHash !== pair.oldHash) ?
                pair.newNode :
                pair.oldNode;

            // Skip this node if it's already been attached to the DOM
            if ($.contains(rootNode, keepNode)) {
                continue;
            }

            // Decide which node to remove
            var loseNode = (keepNode === pair.newNode) ?
                pair.oldNode :
                pair.newNode;

            // Emit a pageupdate event on the existing element
            var event = getEvent('pageupdate', {
                replaceWith: keepNode,
            });

            $(loseNode).trigger(event);

            // Abort if the default action was prevented
            if (event.isDefaultPrevented()) {
                continue;
            }

            // Set a new keepNode if the event listener has provided one
            if (event.replaceWith && event.replaceWith !== keepNode) {
                keepNode = $(event.replaceWith).first();
            }

            // Swap the nodes
            swapNodes(keepNode, loseNode);

            // Keep track of the newly-added nodes
            if (keepNode !== pair.oldNode) {
                addedElements.push(keepNode);
            }

        }

        // Initialise the added alements
        mtl.alloy.process(addedElements);

        // Highlight the added elements
        app.markUpdated(addedElements);

    }


    /**
     * Refresh page fragments by loading the given URL.
     */
    function fromUrl(url) {

        var url = url + '';

        var request = $.ajax(url, {
            method: 'GET',
            cache: false,
        });

        request.done(fromContent);

    }


    /**
     * Refresh page fragments by reloading the current URL.
     */
    function refresh() {

        fromUrl(window.location);

    }


    // Export public API
    app.pageUpdate = {
        fromContent: fromContent,
        fromUrl: fromUrl,
        refresh: refresh,
    };

})(jQuery);
