;(function($, alloy) {
    'use strict';

    /**
     * Initialises an input as a date picker.
     */
    alloy.datePicker = function(element, options) {
        $(element).datepicker(
        {
            changeMonth: true,
            changeYear: true,
            dateFormat: "dd/mm/yy"
        }).css('z-index', '99999');
    };

}(jQuery, mtl.alloy.factory));
