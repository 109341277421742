mtl.alloy.factory['tabbed-content'] = (function($) {
    'use strict';

    /**
     * Controller for tabbed content.
     */
    return function(element, options) {

        const $element = $(element);
        const $scope = $element.closest(options.scope);

        showDefaultIndex(true);

        $scope.on('click', options.tabs, onClickTab);

        /**
         * @param {MouseEvent} event
         */
        function onClickTab(event) {

            const tabs = $scope.find(options.tabs);

            const index = tabs.index(event.currentTarget);
            if (-1 === index) {
                return;
            }

            showIndex(index, false, tabs);

        }

        /**
         * @param {boolean} [instant]
         */
        function showDefaultIndex(instant) {

            const tabs = $scope.find(options.tabs);
            const checked = tabs.filter(':checked').get(0);

            const index = checked ? tabs.index(checked) : -1;
            showIndex(index, instant, tabs);

        }

        /**
         * @param {number} index
         * @param {boolean} [instant]
         * @param {jQuery} [_tabs]
         */
        function showIndex(index, instant, _tabs) {

            const tabs = _tabs ?? $scope.find(options.tabs);

            const containers = $scope.find(options.content);
            const container = containers.eq(index);

            showContainerContents(container, instant);

            tabs.each(function (i, tab) {
                tab.checked = (i === index);
            });

        }

        /**
         * Show the correct tab container for the selected tab
         *
         * @param container
         */
        function showContainerContents(container, instant) {
            if (container.hasClass("is-hidden") || instant === true) {
                container.removeClass("is-hidden").stop(true, false);
                if (instant !== true) {
                    container.css('opacity', 0).animate({ opacity: 1 }, 200);
                }
                container.siblings().addClass("is-hidden");
            }
        }
    };

}($));
