;(function($, alloy) {
    'use strict';

    /**
     * Trigger a in-page content refresh.
     */
    alloy.pageUpdate = function (element, options) {

        if (!app.modal.isActive && !options.force) {
            return;
        }

        if (options.url) {
            app.pageUpdate.fromUrl(options.url);
        }
        else {
            app.pageUpdate.refresh();
        }

    };

})(jQuery, mtl.alloy.factory);
