;(function ($) {
    'use strict';

    var app = window.app = (window.app || {});

    var defaultOptions = {

        className: 'is-updated'

    };


    /**
     * Marks the given elements as having been updated.
     */
    function markUpdated(elements, options) {

        options = $.extend({}, defaultOptions, options || {});

        var $elements = $(elements);

        var isModalActive = app.modal.isActive();

        // Add the is-updated flag to all elements
        $elements.addClass(options.className);

        // If the modal is open, wait for it to close then clear all is-updated flags
        if (isModalActive) {
            app.modal.one('afterDeactivate', function () {
                setTimeout(function () {
                    $elements.removeClass(options.className);
                }, 600);
            });
        }

        // Clear the is-updated flag from elements only if the modal is not active, or the if element is inside the modal
        setTimeout(function () {
            $elements.each(function (i, element) {
                if (!isModalActive || app.modal.contains(element)) {
                    $(element).removeClass(options.className);
                }
            });
        }, 400);

    }


    // Export public API
    app.markUpdated = markUpdated;

})(jQuery);
