;(function($, alloy) {
    'use strict';

    /**
     * Initialises the element as a debt reconciliation form.
     */
    alloy.reconcileDebtForm = function (element, options) {

        var outstandingAmount = parseInt($(element).attr('data-outstanding-amount')) | 0;


        /**
         * Update the remaining write-off input.
         */
        function updateWriteOff() {

            var operatorChargeFloat = $(element).find('input[data-input="operatorCharge"]').val() * 100;

            var operatorCharge = (!isNaN(operatorChargeFloat)) ?
                Math.round(operatorChargeFloat) | 0 :
                outstandingAmount;

            var writeOff = outstandingAmount - operatorCharge;

            $(element).find('input[data-input="writeOff"]').val((writeOff * 0.01).toFixed(2));

        }


        // Update the write-off input whenever the charge-operator input changes
        $(element).on('input cut paste change', 'input[data-input="operatorCharge"]', updateWriteOff);

    };

})(jQuery, mtl.alloy.factory);
