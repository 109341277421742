;(function($, alloy) {
    'use strict';

    var defaultOptions = {

        // Parent scope selector
        scope: 'body',

        // Input selector
        input: 'input[type="checkbox"]',

    };


    /**
     * Initialises the action bar.
     */
    alloy.actionBar = function(element, options) {

        options = $.extend({}, defaultOptions, options || {});

        var scope = $(element).closest(options.scope || 'body').get(0);
        if (scope == null) {
            console.error('Could not find scope %o from %o', options.scope, element);
            return;
        }

        var inputSelector = options.input || 'input[type="checkbox"]';

        var isScheduled = false;


        /**
         * Schedules a visibility update to occur at the soonest possible moment.
         *
         * Deferring the update in this way avoids hammering the DOM when multiple checkboxes change at once.
         */
        function scheduleUpdate() {

            if (isScheduled) {
                return;
            }

            isScheduled = true;

            fastdom.mutate(function() {
                updateVisibility();
                isScheduled = false;
            });

        }


        /**
         * Hides or shows the action bar based on selected checkboxes.
         */
        function updateVisibility() {

            var $element = $(element);

            var isActive = $element.hasClass('is-active');
            var setActive = $(scope).find(inputSelector).is(':checked');
            if (setActive === isActive) {
                return;
            }

            $element.toggleClass('is-active', setActive);

            // Reset all forms when the action bar becomes visible
            if (setActive) {
                $element.find('form').each(function (i, form) {
                    form.reset();
                });
            }

        }


        /**
         * Updates the action bar visibility after a short delay.
         */
        var delayedUpdate = util.debounce(updateVisibility, 800);


        /**
         * Populates the given form with the selected targets.
         */
        function populateFormCollection(form) {

            var namePrefix = (form.hasAttribute('data-collection-name')) ?
                form.getAttribute('data-collection-name') :
                form.name + '[targets]';

            var hiddenData = ['<div class="action-bar__hidden-data">'];

            $(scope).find(inputSelector).filter(':checked').each(function (i, input) {
                var name = namePrefix + '[' + i + ']';
                hiddenData.push('<input type="hidden" name="' + name + '" value="' + input.value + '" />');
            });

            hiddenData.push('</div>');

            $(form).find('.action-bar__hidden-data').remove();
            $(form).append(util.parseHTML(hiddenData.join('')));

        }


        // Listen for target check/unchecks
        $(scope).on('change', inputSelector, scheduleUpdate);

        // Listen for form submissions inside the action bar
        $(element).on('submit', function (event) {
            var form = $(event.target).closest('form')[0];
            if (form) {
                populateFormCollection(form);
            }
            delayedUpdate();
        });

        // Refresh visibility on page update
        $(scope).on('pageupdate', delayedUpdate);

        // Immediately schedule a visibility update
        scheduleUpdate();

    };

}(jQuery, mtl.alloy.factory));
